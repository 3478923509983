import withStyles from '@material-ui/core/styles/withStyles'
import { Refresh } from '@material-ui/icons'
import LinkIcon from '@material-ui/icons/Link'
import LinkOffIcon from '@material-ui/icons/LinkOff'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { withWS } from '../../Business/Websocket/Context'
import { sendBuffer } from '../../Eac/EacActions'
import PdvServiceManager, { DLL_WS_URI, pdvManager } from './PdvServiceManager'

const debug = require('debug')('nerus:pdv-service')

class PdvService extends PureComponent {
    state = {
        connected: pdvManager.current && pdvManager.current.isConnected(),
        connecting: false,
        status:
            pdvManager.current && pdvManager.current.isConnected()
                ? 'Conectado.'
                : 'Conectando ao serviço...',
        substatus: 'Status OK',
    }

    static mapStateToProps = state => {
        return {
            dll: state.eac.dll,
        }
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        dll: PropTypes.array.isRequired,
        ws: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
    }

    static events = {
        STATE_CHANGE({ state }) {
            debug('STATE_CHANGE: ', state)

            switch (state) {
                case PdvServiceManager.CLOSED: {
                    debug('[WS] Conexão encerrada.')
                    if (this.state.status !== 'Desconectado.') {
                        this.setState({
                            status: 'Desconectado.',
                            substatus: 'Verifique se o serviço está ativo.',
                            connected: false,
                            connecting: false,
                        })
                    }
                    break
                }
                case PdvServiceManager.CONNECTING: {
                    debug('[WS] Conectando...')
                    if (this.state.status !== 'Conectando ao serviço...') {
                        this.setState({
                            status: 'Conectando ao serviço...',
                            substatus: '',
                            connecting: true,
                        })
                    }
                    break
                }
                case PdvServiceManager.WAITING: {
                    debug('[WS] Aguardando...')
                    if (
                        this.state.status !== 'Conectado.' ||
                        this.state.substatus !== 'Aguardando retorno...'
                    ) {
                        this.setState({
                            status: 'Conectado.',
                            substatus: 'Aguardando retorno...',
                            connecting: false,
                            connected: true,
                        })
                    }
                    break
                }
                case PdvServiceManager.CONNECTED: {
                    debug('[WS] Conectado.')
                    if (
                        (this.state.status !== 'Conectado.' &&
                            this.state.substatus !== 'Status OK') ||
                        !this.state.connected
                    ) {
                        this.setState({
                            status: 'Conectado.',
                            substatus: 'Status OK',
                            connected: true,
                            connecting: false,
                        })
                    }
                    break
                }
                case PdvServiceManager.PROCESSING: {
                    debug('[WS] Processando...')
                    break
                }
            }
        },
        sending() {
            if (
                this.state.status !== 'Conectado.' ||
                this.state.substatus !== 'Processando...'
            ) {
                this.setState({
                    status: 'Conectado.',
                    substatus: 'Processando...',
                    connecting: false,
                })
            }
        },
        message(message) {
            if (
                this.state.status !== 'Conectado.' ||
                this.state.substatus !== 'Status OK' ||
                this.state.connected !== true ||
                this.state.connecting !== false
            ) {
                this.setState({
                    status: 'Conectado.',
                    substatus: 'Status OK',
                    connected: true,
                    connecting: false,
                })
            }

            if (message?.type === 'dllret') {
                this.props.ws.send(sendBuffer(message.message, 'DLLRet'))
            }

            if (message?.type === 'fingerprintret') {
                if (message?.data?.value === true) message.data.value = 'OK'

                this.props.ws.send(
                    sendBuffer(
                        {
                            ...message.data,
                            key: 13,
                        },
                        'FingerprintRet'
                    )
                )
            }
        },
    }

    constructor(props) {
        super(props)

        this.eventsBind = {}
        Object.keys(PdvService.events).forEach(event => {
            this.eventsBind[event] = PdvService.events[event].bind(this)
        })
    }

    componentWillUnmount() {
        super.componentWillUnmount && super.componentWillUnmount()

        // Faz unbind dos eventos que tratamos
        Object.keys(PdvService.events).forEach(event => {
            pdvManager.current.off(event, this.eventsBind[event])
        })

        this.props.ws.off('DLL', this.onDLLEvent)
        this.props.ws.off('Fingerprint', this.onFingerprintEvent)
    }

    onDLLEvent = wsMessage => {
        pdvManager.current.send({
            type: 'dll',
            message: wsMessage.data,
        })
    }

    onFingerprintEvent = wsMessage => {
        pdvManager.current.send({
            type: 'fingerprint',
            data: wsMessage.data,
        })
    }

    componentDidMount() {
        if (!pdvManager.current) {
            pdvManager.current = new PdvServiceManager()
        }

        // Faz bind dos eventos que tratamos
        Object.keys(PdvService.events).forEach(event => {
            pdvManager.current.off(event, this.eventsBind[event])
            pdvManager.current.on(event, this.eventsBind[event])
        })

        this.props.ws.off('DLL', this.onDLLEvent)
        this.props.ws.on('DLL', this.onDLLEvent)

        this.props.ws.off('Fingerprint', this.onFingerprintEvent)
        this.props.ws.on('Fingerprint', this.onFingerprintEvent)

        const {
            props: { match },
        } = this
        if (!match?.params?.binario || match?.params?.binario === 'pv') {
            return null
        } else {
            if (pdvManager.current && !pdvManager.current.isConnected()) {
                pdvManager.current.connect(DLL_WS_URI)
            }
        }
    }

    refresh = () => {
        pdvManager.current.reconnect()
    }

    render() {
        const {
            props: { classes, match },
            state: { status, substatus, connected, connecting },
        } = this

        if (!match?.params?.binario || match?.params?.binario === 'pv') {
            return null
        }

        return (
            <div className={classes.root}>
                <div className={classes.icon || ''}>
                    {!connected ? <LinkOffIcon /> : <LinkIcon />}
                </div>
                <div className={classes.status}>
                    <div className={classes.statusText}>
                        {status}
                        {substatus ? (
                            <div>
                                <span className={classes.substatus}>
                                    {substatus}
                                </span>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    {!connected && !connecting ? (
                        <div
                            onClick={this.refresh}
                            className={classes.reconnect}
                        >
                            <Refresh />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        )
    }
}

export default withRouter(
    withWS(
        connect(PdvService.mapStateToProps)(
            withStyles(theme => ({
                root: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: theme.spacing(2),
                    marginRight: theme.spacing(2),
                    '& *': { margin: 0 },
                    background: 'rgba(0,0,0,0.05)',
                    lineHeight: '12px',
                },
                icon: {
                    marginRight: theme.spacing(1),
                    display: 'flex',
                },
                statusText: {
                    fontWeight: 'bold',
                },
                substatus: {
                    lineHeight: '10px',
                    fontWeight: 'normal',
                },
                status: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                reconnect: {
                    cursor: 'pointer',
                },
            }))(PdvService)
        )
    )
)
