import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from '@nerus/styles/login'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Slider from 'react-slick/lib'

import { Logout } from './Logout'

class AlreadyConnected extends Logout {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
    }

    doRetry = () => this.doReset()

    render() {
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            adaptiveHeight: true,
            autoplay: true,
            autoplaySpeed: 10000,
        }

        const { classes } = this.props

        return (
            <div
                className={clsx({
                    [classes.root]: true,
                    [classes.fontConthrax]: true,
                })}
            >
                <div
                    className={clsx({
                        [classes.content]: true,
                    })}
                >
                    <div
                        className={clsx({
                            [classes.banner]: true,
                            banner: true, // TODO: remover ao tirar o react-slick
                        })}
                    >
                        <Slider {...settings}>{this.state.slides}</Slider>
                    </div>
                    <div className={classes.formWrapper}>
                        <div className={classes.callingWrapper}>
                            <h2 className={classes.caller}>Ops!</h2>
                            <h3 className={classes.welcomeText}>
                                Você já possui uma sessão iniciada para este
                                binário,
                                <br /> verifique suas janelas abertas ou inicie
                                uma nova sessão em aba anônima.
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AlreadyConnected.propTypes = {
    classes: PropTypes.object,
}

export default connect()(withStyles(styles)(AlreadyConnected))
