/**
 * Define o tema que está em uso do MaterialUI
 *
 * @type {[type]}
 */
class newLocalStorage {
    static prefix = 'nerus_'
    session_id = null

    getIndex(key, skipPrefix = false) {
        let index = ''
        if (!skipPrefix) {
            index += newLocalStorage.prefix
        }

        index += `${key}`

        return index
    }

    getKeys() {
        if (typeof localStorage !== 'undefined') {
            return Object.keys(localStorage)
        }
        return []
    }

    get(key, defaultValue = null, skipPrefix = false) {
        if (typeof localStorage !== 'undefined') {
            const index = this.getIndex(key, skipPrefix)
            const value = localStorage.getItem(index)
            try {
                const parsedValue = JSON.parse(value)
                return parsedValue !== null ? parsedValue : defaultValue
            } catch (e) {
                /*
                 * É esperado que o JSON.parse falhe
                 * mas não é problema, por isso, não
                 * fazemos nada
                 */
            }
            return defaultValue
        }
    }

    set(key, value) {
        if (typeof localStorage !== 'undefined') {
            const index = this.getIndex(key)
            let pval = value
            if (typeof pval === 'object') {
                pval = JSON.stringify(pval)
            }
            return localStorage.setItem(index, pval)
        }
    }

    remove(key, skipPrefix = false) {
        const index = this.getIndex(key, skipPrefix)
        if (typeof localStorage !== 'undefined') {
            return localStorage.removeItem(index)
        }
    }

    clear() {
        if (typeof localStorage !== 'undefined') {
            // o localStorage.clear() tem alguns bugs
            // Ref: https://stackoverflow.com/questions/7667958/clearing-localstorage-in-javascript#comment86201589_7667973
            return Object.keys(localStorage).forEach(key => {
                // Limpa apenas chaves que façam parte da sessão corrente
                if (this.session_id && key.indexOf(this.session_id) > -1) {
                    localStorage.removeItem(key)
                }
            })
        }
    }
}

export default new newLocalStorage()
